<script setup lang="ts">
import { useTheme } from 'vuetify'
import { isDark } from '~/composables/utils'

onMounted(() => { useTheme().global.name.value = isDark() ? 'dark' : 'light' })
</script>

<template>
  <v-app>
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<style scoped lang="scss">
.fake::-webkit-scrollbar {
  width: 0 !important;
}
</style>
